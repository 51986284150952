// ** React Imports
import { create } from 'axios'

// ** Axios instance
const axios = create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 120000,
  headers: {
    'content-type': 'application/json'
  }
})

// ** Headers
axios.defaults.withCredentials = true
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
axios.defaults.xsrfCookieName = 'csrftoken'

export default axios