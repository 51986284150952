import axios from '@src/axios'
import { getData as getAdminData } from '@src/views/admin/store/action'

// ** Get all Data
export const getAllData = () => {
  return async dispatch => {
    await axios.get('/api/account/all').then(response => {
      dispatch({
        type: 'GET_ALL_ACCOUNTS',
        data: response.data.accounts
      })
    })
  }
}

// ** Get data on page or row change
export const getData = params => {
  return async (dispatch, getState) => {
    const paramsGroup = []
    const otherUser = getState().users.selectedOtherUser
    if (otherUser !== undefined && otherUser !== null) {
      paramsGroup.push(`?id=${otherUser}`)
    }
    await axios.get(`/api/account/data${paramsGroup.join('&')}`, params).then(response => {
      dispatch({
        type: 'GET_DATA',
        data: response.data.accounts,
        totalPages: response.data.accounts.length,
        params
      })
    })
  }
}

// ** Get available accounts
export const getAccountList = params => {
  return async (dispatch, getState) => {
    const paramsGroup = []
    const otherUser = getState().users.selectedOtherUser
    if (otherUser !== undefined && otherUser !== null) {
      paramsGroup.push(`?id=${otherUser}`)
    }
    await axios.get(`/api/account/list${paramsGroup.join('&')}`, params).then(response => {
      dispatch({
        type: 'SET_ACCOUNT_LIST',
        accountList: response.data.accounts
      })
    })
  }
}

// ** Get account details
export const getAccountDetails = params => {
  const paramsGroup = []
  if (params.account !== undefined && params.account !== null) {
    paramsGroup.push(`account=${params.account}`)
  }

  if (params.id !== undefined && params.id !== null) {
    paramsGroup.push(`id=${params.id}`)
  }

  return async (dispatch, getState) => {
    const otherUser = getState().users.selectedOtherUser
    if (otherUser !== undefined && otherUser !== null) {
      paramsGroup.push(`user_id=${otherUser}`)
    }
    await axios.get(`/api/account/details?${paramsGroup.join('&')}`).then(response => {
      dispatch({
        type: 'SET_ACCOUNT',
        selectedAccount: {
          ...response.data.account,
          logo: require(`@src/assets/images/icons/accounts/${response.data.account.name.toLowerCase()}.png`).default
        }
      })
      dispatch(getAccountList())
    })
  }
}

// ** Set account details
export const setAccountDetails = value => dispatch => dispatch({ type: 'SET_ACCOUNT', selectedAccount: value })

// ** Add new user
export const addUser = user => {
  return (dispatch, getState) => {
    axios
      .post('/api/account/add', user)
      .then(response => {
        dispatch({
          type: 'ADD_ACCOUNT',
          user
        })
      })
      .then(() => {
        if (getState().users.selectedAccount !== null && getState().users.selectedAccount !== undefined) {
          dispatch(getAccountDetails({ account: getState().users.selectedAccount.name }))
        }
        dispatch(getAllData())
        dispatch(getAccountList())
      }) 
      .catch(err => console.log(err))
  }
}

// ** Send 2FA code
export const sendTwoFactor = code => {
  return (dispatch, getState) => {
    axios
      .post('/api/account/twofactor', {
        ...code,
        id: code.id === undefined ? getState().users.userValidate : code.id
      })
      .then(() => {
        if (getState().users.selectedAccount !== null && getState().users.selectedAccount !== undefined) {
          dispatch(getAccountDetails({ account: getState().users.selectedAccount.name }))
        }
        dispatch(getAllData())
        dispatch(getAccountList())
      })
      .catch(err => console.log(err))
  }
}

// ** Send Captcha
export const sendCaptcha = code => {
  return (dispatch, getState) => {
    axios
      .post('/api/account/captcha', {
        ...code,
        id: code.id === undefined ? getState().users.userValidate : code.id
      })
      .then(() => {
        if (getState().users.selectedAccount !== null && getState().users.selectedAccount !== undefined) {
          dispatch(getAccountDetails({ account: getState().users.selectedAccount.name }))
        }
        dispatch(getAllData())
        dispatch(getAccountList())
      })
      .catch(err => console.log(err))
  }
}

// ** Delete user
export const deleteUser = id => {
  return (dispatch, getState) => {
    axios
      .post('/api/account/delete', { id })
      .then(response => {
        dispatch({
          type: 'DELETE_ACCOUNT'
        })
      })
      .then(() => {
        dispatch(getAllData())
        dispatch(getAccountList())
      })
  }
}

// ** Validate user creds
export const validateUser = id => {
  return (dispatch, getState) => {
    axios
      .post('/api/account/validate', { id })
      .then(() => {
        dispatch(getAllData())
        dispatch(getAccountList())
      })
  }
}

// ** Validate user creds
export const reValidateUser = id => {
  return (dispatch, getState) => {
    axios
      .post('/api/account/revalidate', { id })
      .then(() => {
        dispatch(getAllData())
        dispatch(getAccountList())
      })
  }
}

// ** Running user creds
export const runningUser = id => {
  return (dispatch, getState) => {
    axios
      .post('/api/account/running', { id })
      .then(() => {
        const user = getState().profile.selectedUser
        if (user.role === 'business') {
          dispatch(getData())
        } else {
          dispatch(getAllData())
          dispatch(getAccountList())
        }
      })
  }
}

// ** Stopped user creds
export const stoppedUser = id => {
  return (dispatch, getState) => {
    axios
      .post('/api/account/stopped', { id })
      .then(() => {
        const user = getState().profile.selectedUser
        if (user.role === 'business') {
          dispatch(getData())
        } else {
          dispatch(getAllData())
          dispatch(getAccountList())
        }
      })
  }
}

// ** Set user for sidebar
export const setValidateUser = id => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_USER_VALIDATE',
      id
    })
  }
}

// ** Set other user
export const setOtherUser = value => dispatch => {
  dispatch({ type: 'SET_BUSINESS_OTHER_USER', value })
  if (value === null) {
    dispatch({ type: 'GET_OTHER_USER', selectedOtherUser: value })
  }
}
