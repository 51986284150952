import axios from '@src/axios'

// ** GET all notifications
export const getNotifications = () => {
  return async dispatch => {
    await axios.get('/api/notification/all').then(response => {
      dispatch({
        type: 'NOTIFICATION_ALL',
        data: response.data.notifications
      })
    })
  }
}

// ** SELECT Current Mail
export const selectCurrentMail = id => dispatch => {
  const params = { uuid: id }
  return axios.get('/api/notification/details', { params }).then(res => {
    dispatch({ type: 'SELECT_CURRENT_MAIL', mail: res.data })
  })
}