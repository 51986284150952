import axios from '@src/axios'
import { toast } from 'react-toastify'
import { Fragment } from 'react'
import { Info } from 'react-feather'
import Avatar from '@components/avatar'
import { FormattedMessage } from 'react-intl'

const InfoToast = ({ title, color }) => (
  <Fragment>
    <div className='toastify-header'>
      <div className='title-wrapper'>
        <Avatar size='sm' color={color} icon={<Info size={12} />} />
        <h6 className={`text-${color} ml-50 mb-0`}>
          <FormattedMessage id={title} />
        </h6>
      </div>
    </div>
  </Fragment>
)

const notifyBottom = (title, color) => toast.info(<InfoToast title={title} color={color} />, {
  position: toast.POSITION.BOTTOM_RIGHT,
  hideProgressBar: true,
  autoClose: 3000
})

// ** Get User
export const getUser = () => {
  return async (dispatch, getState) => {
    let params = ''
    const otherUser = getState().users.selectedOtherUser
    const isOtherUser = otherUser !== undefined && otherUser !== null
    if (isOtherUser) {
      params = `?id=${otherUser}`
    }
    await axios
      .get(`/api/profile/user${params}`)
      .then(response => {
        if (!isOtherUser) {
          dispatch({
            type: 'GET_USER',
            selectedUser: response.data.profile
          })
        } else {
          dispatch({
            type: 'GET_OTHER_USER',
            selectedOtherUser: response.data.profile
          })
        }
      })
      .catch(err => console.log(err))
  }
}

// ** Get Plan
export const getPlan = (id = 0) => {
  return async dispatch => {
    await axios
      .get('/api/account/plan', { id })
      .then(response => {
        dispatch({
          type: 'GET_PLAN',
          plan: response.data
        })
      })
      .catch(err => console.log(err))
  }
}

// ** Get Jobs
export const getJobs = params => {
  return async dispatch => {
    await axios
      .get('/api/jobs/all', { params })
      .then(response => {
        dispatch({
          type: 'GET_ALL_JOBS',
          allJobs: response.data.jobs
        })
      })
      .catch(err => console.log(err))
  }
}

// ** Get Timezones
export const getTimezones = params => {
  return async dispatch => {
    await axios
      .get('/api/timezone/all', { params })
      .then(response => {
        dispatch({
          type: 'GET_ALL_TIMEZONE',
          allTimezones: response.data.timezones
        })
      })
      .catch(err => console.log(err))
  }
}

// ** Update User
export const updateUser = obj => {
  return async (dispatch, getState) => {
    let params = ''
    const otherUser = getState().users.selectedOtherUser
    const isOtherUser = otherUser !== undefined && otherUser !== null
    if (isOtherUser) {
      params = `?id=${otherUser}`
    }
    await axios
      .post(`/api/profile/update/${params}`, { ...obj })
      .then(response => {
        dispatch({ type: 'UPDATE_USER' })
        notifyBottom('user_updated', 'primary')
      })
      .then(() => {
        dispatch(getUser())
      })
      .catch(() => {
        notifyBottom('error_ocurred', 'error')
      })
  }
}

// ** Add comment
export const addUserComment = obj => {
  return async (dispatch) => {
    await axios
      .post('/api/profile/comment/', obj)
      .then(() => {
        dispatch({ type: 'UPDATE_USER' })
      })
      .then(() => {
        dispatch(getUser())
      })
      .catch(() => {
        notifyBottom('error_ocurred', 'error')
      })
  }
}

// ** Update User
export const updateCompany = obj => {
  return async (dispatch, getState) => {
    let params = ''
    const otherUser = getState().users.selectedOtherUser
    const isOtherUser = otherUser !== undefined && otherUser !== null
    if (isOtherUser) {
      params = `?id=${otherUser}`
    }
    await axios
      .post(`/api/company/user/${params}`, { ...obj })
      .then(response => {
        dispatch({ type: 'UPDATE_USER' })
        notifyBottom('user_updated', 'primary')
      })
      .then(() => {
        dispatch(getUser())
      })
      .catch(() => {
        notifyBottom('error_ocurred', 'error')
      })
  }
}

// ** Update curriculum
export const updateCurriculum = obj => {
  return async (dispatch, getState) => {
    let params = ''
    const otherUser = getState().users.selectedOtherUser
    const isOtherUser = otherUser !== undefined && otherUser !== null
    if (isOtherUser) {
      params = `?id=${otherUser}`
    }
    await axios
      .post(`/api/profile/upload/${params}`, obj, {
        'content-type': 'application/form-data'
      })
      .then(response => {
        dispatch({ type: 'UPDATE_USER' })
        notifyBottom('user_updated', 'primary')
      })
      .then(() => {
        dispatch(getUser())
      })
      .catch(() => {
        notifyBottom('error_ocurred', 'error')
      })
  }
}

// ** Update curriculum
export const editCurriculum = obj => {
  return async (dispatch, getState) => {
    let params = ''
    const otherUser = getState().users.selectedOtherUser
    const isOtherUser = otherUser !== undefined && otherUser !== null
    if (isOtherUser) {
      params = `?id=${otherUser}`
    }
    await axios
      .post(`/api/profile/edit/${params}`, { ...obj })
      .then(response => {
        dispatch({ type: 'UPDATE_USER' })
        notifyBottom('user_updated', 'primary')
      })
      .then(() => {
        dispatch(getUser())
      })
      .catch(() => {
        notifyBottom('error_ocurred', 'error')
      })
  }
}

// ** Update curriculum
export const deleteCurriculum = obj => {
  return async (dispatch, getState) => {
    let params = ''
    const otherUser = getState().users.selectedOtherUser
    const isOtherUser = otherUser !== undefined && otherUser !== null
    if (isOtherUser) {
      params = `?id=${otherUser}`
    }
    await axios
      .post(`/api/profile/delete/${params}`, { ...obj })
      .then(response => {
        dispatch({ type: 'UPDATE_USER' })
        notifyBottom('user_updated', 'primary')
      })
      .then(() => {
        dispatch(getUser())
      })
      .catch(() => {
        notifyBottom('error_ocurred', 'error')
      })
  }
}

// ** Update Tags
export const updateTags = obj => {
  return async (dispatch, getState) => {
    let params = ''
    const otherUser = getState().users.selectedOtherUser
    const isOtherUser = otherUser !== undefined && otherUser !== null
    if (isOtherUser) {
      params = `?id=${otherUser}`
    }
    await axios
      .post(`/api/profile/tags/${params}`, obj)
      .then(response => {
        dispatch({ type: 'UPDATE_USER' })
        notifyBottom('user_updated', 'primary')
      })
      .then(() => {
        dispatch(getUser())
      })
      .catch(() => {
        notifyBottom('error_ocurred', 'error')
      })
  }
}

// ** Get Tags
export const getTags = () => {
  return async dispatch => {
    await axios
      .get('/api/profile/tags')
      .then(response => {
        dispatch({
          type: 'GET_TAGS',
          allTags: response.data.tags
        })
      })
      .catch(err => console.log(err))
  }
}

// ** Get Countries
export const getCountries = (params, mode) => {
  return async dispatch => {
    await axios
      .get('/api/jobs/country', { params })
      .then(response => {
        if (mode === 1) {
          dispatch({
            type: 'GET_COUNTRIES',
            allCountries: response.data.countries
          })
        } else {
          dispatch({
            type: 'GET_LOCATIONS',
            allLocations: response.data.countries
          })
        }
      })
      .catch(err => console.log(err))
  }
}

export const setPayment = ({ token, payerID }) => {
  return async dispatch => {
    dispatch({
      type: 'SET_PAYPAL_PAYMENT',
      token,
      payerID
    })
  }
}

export const getListData = () => {
  return async (dispatch, getState) => {
    // UPDATE DASHBOARD
    const params = getState().dashboard.params || {}
    params.offset = (new Date()).getTimezoneOffset()
    const otherUser = getState().users.selectedOtherUser
    if (otherUser !== undefined && otherUser !== null) {
      params.id = otherUser
    }

    await axios.get('/api/dashboard/list/', { params }).then(response => {
      dispatch({
        type: 'LIST_DASHBOARD',
        data: response.data.data,
        daily: response.data.daily,
        sents: response.data.sents,
        accounts: response.data.accounts
      })
    })
  }
}