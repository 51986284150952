// ** Initial State
const initialState = {
  data: [],
  total: 1,
  page: 1,
  params: {},
  details: {},
  control: [],
  controlPage: 1,
  controlTotal: {},
  controlParams: {}
}

const admin = (state = initialState, action) => {
  switch (action.type) {
    case 'COMPANY_USERS_LIST':
      return {
        ...state,
        data: action.users,
        total: action.total,
        details: action.details
      }
    case 'COMPANY_SET_PARAMS':
      window.localStorage.setItem('adminParams', action.value)
      return { ...state, params: action.value }
    case 'COMPANY_SET_CONTROL_PARAMS':
      window.localStorage.setItem('controlParams', action.value)
      return { ...state, controlParams: action.value }
    case 'COMPANY_SET_CONTROL_DATA':
      return {
        ...state,
        control: action.users,
        controlTotal: action.total
      }
    default:
      return { ...state }
  }
}
export default admin
