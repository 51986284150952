// ** Initial State
const initialState = {
  // ** Autocompletes
  allJobs: [],
  allCountries: [],
  totalCountries: [],
  allLocations: [],
  // ** User created
  uuid: undefined
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_JOBS_AVAILABLE':
      return { ...state, allJobs: action.allJobs }
    case 'GET_ALL_COUNTRIES':
      return { ...state, allCountries: action.allCountries }
    case 'GET_TOTAL_COUNTRIES':
      return { ...state, totalCountries: action.totalCountries }
    case 'GET_ALL_LOCATIONS':
      return { ...state, allLocations: action.allLocations }
    case 'SET_UUID_REGISTERED':
      return { ...state, uuid: action.value.uuid }
    default:
      return { ...state }
  }
}
export default profile
