import axios from '@src/axios'

export const setAdminParams = value => dispatch => dispatch({ type: 'COMPANY_SET_PARAMS', value })

export const setControlParams = value => dispatch => dispatch({ type: 'COMPANY_SET_CONTROL_PARAMS', value })

export const getData = () => {
  return async (dispatch, getState) => {
    const params = getState().admin.params || {}
    params.offset = (new Date()).getTimezoneOffset()
    await axios.get('/api/company/user/', { params }).then(response => {
      dispatch({
        type: 'COMPANY_USERS_LIST',
        ...response.data
      })
    })
  }
}

export const setUserActive = id => {
  return async dispatch => {
    await axios.post('/api/company/active/', { id }).then(response => {
      dispatch(getData({}))
    })
  }
}

export const deleteUser = id => {
  return async dispatch => {
    await axios.post('/api/company/delete/', { id }).then(response => {
      dispatch(getData({}))
    })
  }
}

export const addUser = data => {
  return async dispatch => {
    await axios.post('/api/company/add/', data).then(response => {
      dispatch(getData({}))
    })
  }
}

export const getDashboard = () => {
  return async (dispatch, getState) => {
    const params = getState().admin.controlParams || {}
    params.offset = (new Date()).getTimezoneOffset()
    await axios.get('/api/company/control/', { params }).then(response => {
      dispatch({
        type: 'COMPANY_SET_CONTROL_DATA',
        ...response.data
      })
    })
  }
}