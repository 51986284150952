
// ** Initial State
const initialState = {
  page: 1,
  params: {},
  // ** User Dashboard
  data: [],
  daily: 0,
  sents: 0,
  accounts: 0,
  // ** Admin Dashboard
  applies_area: [],
  applies_platform: [],
  applies_coach: [],
  applies_account: [],
  applies_active: [],
  // ** General Dashboard
  general_average: [],
  general_country: [],
  general_redirect: [],
  general_duration: [],
  general_monthly: [],
  general_competitors: [],
  general_platform: [],
  general_last_week: [],
  general_last_platform: [],
  general_last_applies: [],
  general_availability: []
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case 'DASHBOARD_JOBS':
      return {
        ...state,
        general_average: action.general_average,
        general_country: action.general_country,
        general_redirect: action.general_redirect,
        general_duration: action.general_duration,
        general_monthly: action.general_monthly,
        general_competitors: action.general_competitors,
        general_platform: action.general_platform,
        general_last_week: action.general_last_week,
        general_last_platform: action.general_last_platform,
        general_last_applies: action.general_last_applies,
        general_availability: action.general_availability
      }
    case 'ADMIN_DASHBOARD':
      return {
        ...state,
        applies_area: action.applies_area,
        applies_platform: action.applies_platform,
        applies_coach: action.applies_coach,
        applies_account: action.applies_account,
        applies_active: action.applies_active
      }
    case 'LIST_DASHBOARD':
      return {
        ...state,
        data: action.data,
        daily: action.daily,
        sents: action.sents,
        accounts: action.accounts
      }
    case 'DASHBOARD_SET_PARAMS':
      window.localStorage.setItem('jobParams', action.value)
      return { ...state, params: action.value }
    default:
      return { ...state }
  }
}
export default dashboard
