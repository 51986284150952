// ** Initial State
const initialState = {
  keyword: '',
  account: '',
  country: ''
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SEARCH_KEYWORD':
      return { ...state, keyword: action.value }
    case 'SET_SEARCH_ACCOUNT':
      return { ...state, account: action.value }
    case 'SET_SEARCH_COUNTRY':
      return { ...state, country: action.value }
    default:
      return { ...state }
  }
}
export default users
