// ** Initial State
const initialState = {
  couponError: null,
  couponSuccess: null
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case 'CHECK_COUPON':
      return { 
        ...state, 
        couponError: action.error,
        couponSuccess: action.success
      }
    default:
      return { ...state }
  }
}
export default profile
