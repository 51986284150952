// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import socket from '@src/views/socket/store/reducer'
import users from '@src/views/account/store/reducer'
import profile from '@src/views/profile/store/reducer'
import dashboard from '@src/views/home/store/reducer'
import admin from '@src/views/admin/store/reducer'
import notifications from '@src/views/notifications/store/reducer'
import pricing from '@src/views/pricing/store/reducer'
import login from '@src/views/auth/store/reducer'
import search from '@src/views/search/store/reducer'
import coupon from '@src/views/coupon/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  users,
  socket,
  dashboard,
  notifications,
  profile,
  pricing,
  search,
  login,
  admin,
  coupon
})

export default rootReducer
