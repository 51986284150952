// ** React Imports
import { useState, createContext } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
import messagesEn from '@assets/data/locales/en.json'
import messagesEs from '@assets/data/locales/es.json'
import messagesBr from '@assets/data/locales/br.json'
import messagesFr from '@assets/data/locales/fr.json'

// ** User Language Data
import userMessagesEn from '@src/assets/data/locales/en.json'
import userMessagesEs from '@src/assets/data/locales/es.json'
import userMessagesBr from '@src/assets/data/locales/br.json'
import userMessagesFr from '@src/assets/data/locales/fr.json'

// ** Error Status
import errorMessagesEn from '@assets/data/errors/en.json'
import errorMessagesEs from '@assets/data/errors/es.json'
import errorMessagesBr from '@assets/data/errors/br.json'
import errorMessagesFr from '@assets/data/errors/fr.json'

// ** Country Status
import countryMessagesEn from '@assets/data/countries/en.json'
import countryMessagesEs from '@assets/data/countries/es.json'

// ** Cities Status
import cityMessagesEn from '@assets/data/cities/en.json'
import cityMessagesEs from '@assets/data/cities/es.json'

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn, ...userMessagesEn, ...errorMessagesEn, ...countryMessagesEn, ...cityMessagesEn },
  es: { ...messagesEs, ...userMessagesEs, ...errorMessagesEs, ...countryMessagesEs, ...cityMessagesEs },
  br: { ...messagesBr, ...userMessagesBr, ...errorMessagesBr, ...countryMessagesEn, ...cityMessagesEn },
  fr: { ...messagesFr, ...userMessagesFr, ...errorMessagesFr, ...countryMessagesEn, ...cityMessagesEn }
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  // ** Language
  let language = navigator.language || navigator.userLanguage
  if (language.indexOf('-') !== -1) {
    language = language.split('-')[0]
  }
  if (language.indexOf('_') !== -1) {
    language = language.split('_')[0]
  }
  language = menuMessages[language] === undefined ? 'en' : language

  // ** States
  const [locale, setLocale] = useState(language)
  const [messages, setMessages] = useState(menuMessages[language])

  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang])
  }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale={language}>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
