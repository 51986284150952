import { getNotifications } from '@src/views/notifications/store/action'
import { getAllData, getAccountList, getAccountDetails } from '@src/views/account/store/action'
import { getUser, getPlan, getListData } from '@src/views/profile/store/action'

let websocket = null

const middleware = store => next => action => {
  const { dashboard } = store.getState()

  switch (action.type) {
    case 'WEBSOCKET_CONNECT':
      websocket = new WebSocket(action.payload.url)      
      websocket.onopen = () => store.dispatch({ type: 'WEBSOCKET_OPEN' })
      websocket.onclose = (event) => store.dispatch({ type: 'WEBSOCKET_CLOSE', payload: event })
      websocket.onmessage = (event) => {
        store.dispatch({ type: 'WEBSOCKET_MESSAGE', payload: event })
        store.dispatch({ type: `WEBSOCKET_${JSON.parse(event.data)['status']}`, payload: event })
      }
      break
    case 'WEBSOCKET_DISCONNECT':
      websocket.close()
      break
    // ** Send message
    case 'WEBSOCKET_SEND':
      websocket.send(JSON.stringify(action.payload))
      break
    // ** Get responses
    case 'WEBSOCKET_NOTIFY_UPDATE':
      store.dispatch(getNotifications())
      break
    case 'WEBSOCKET_NOTIFY_PAYMENT':
      store.dispatch(getPlan())
      store.dispatch(getUser())
      break
    case 'WEBSOCKET_NOTIFY_USER':
      if (store.getState().users.selectedAccount !== null && store.getState().users.selectedAccount !== undefined) {
        store.dispatch(getAccountDetails({account: store.getState().users.selectedAccount.name}))
      }
      store.dispatch(getAllData())
      store.dispatch(getAccountList())
      break
    case 'WEBSOCKET_NOTIFY_DASHBOARD':
      store.dispatch(getListData())
      break
    default:
      break
  }

  return next(action)
}

export {
  middleware
}