// ** Initial State
const initialState = {
  allData: [],
  data: [],
  allTimezones: [],
  total: 1,
  params: {},
  allJobs: [],
  allCountries: [],
  allLocations: [],
  allTags: [],
  plan: {},
  selectedUser: null,
  selectedOtherUser: null,
  token: null,
  payerID: null
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser }
    case 'GET_OTHER_USER':
      return { ...state, selectedOtherUser: action.selectedOtherUser }
    case 'GET_PLAN':
      return { ...state, plan: action.plan }
    case 'GET_ALL_JOBS':
      return { ...state, allJobs: action.allJobs }
    case 'GET_COUNTRIES':
      return { ...state, allCountries: action.allCountries }
    case 'GET_LOCATIONS':
      return { ...state, allLocations: action.allLocations }
    case 'GET_TAGS':
      return { ...state, allTags: action.allTags }
    case 'GET_ALL_TIMEZONE':
      return { ...state, allTimezones: action.allTimezones }
    case 'SET_PAYPAL_PAYMENT':
      return { ...state, payerID: action.payerID, token: action.token }
    default:
      return { ...state }
  }
}
export default profile
