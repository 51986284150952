// ** Initial State
const initialState = {
  turnOn: false
}

const socketReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'WEBSOCKET_MESSAGE':
      const data = JSON.parse(action.payload.data)
      return { ...state, ...data }
    case 'WEBSOCKET_OPEN':
      return { ...state, turnOn: true }
    case 'WEBSOCKET_CLOSE':
      return { ...state, turnOn: false }
    default:
      return state
  }
}

export default socketReducer